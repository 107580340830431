import { Listbox, Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import find from 'lodash/find';
import get from 'lodash/get';
import { useTranslation } from 'next-i18next';
import { useRef, Fragment, useState, useEffect } from 'react';

import { useDropdownDirection } from '@/hooks/useDropdownDirection';
import { cn } from '@/utils/cn';

import Option from './Option';

import type { Props } from './index';
import type { ChangeEvent } from 'react';

type DropdownContentsProps = { open: boolean } & Omit<Props, 'onChange' | 'tooltip'>;

const DropdownContents = ({
    open,
    options,
    value,
    icon,
    customSearchHandler,
    dropdownClass,
    buttonClass,
    placeholder,
    hasSearch,
    isSearching,
    stickySelectedOption,
}: DropdownContentsProps) => {
    const { t } = useTranslation('common');
    const inputRef = useRef<HTMLInputElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const [query, setQuery] = useState('');

    useEffect(() => {
        if (open) {
            setQuery('');

            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            });
        }
    }, [open, inputRef, selectRef]);

    const selectedOption = find(options, { value });

    const IconComponent = !!icon && icon;

    const direction = useDropdownDirection(selectRef, 240);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;

        setQuery(query);

        if (customSearchHandler) {
            customSearchHandler(query);
        }
    };

    let filteredOptions =
        query === ''
            ? options
            : options.filter((option) => option.key.toLowerCase().includes(query.toLowerCase()));

    if (stickySelectedOption) {
        filteredOptions = filteredOptions.reduce((previous, current) => {
            if (current.value === value) {
                return [current, ...previous];
            }

            return [...previous, current];
        }, []);
    }

    return (
        <div className="relative" ref={selectRef}>
            <Listbox.Button
                className={cn(
                    'group relative flex h-12 w-full cursor-pointer items-center rounded-lg bg-gray-100 pl-4 text-left text-sm hover:border-blue-500 focus:outline-none',
                    buttonClass,
                )}
            >
                {!!IconComponent && <IconComponent className="size-5" />}
                <span className={cn('block flex-1 truncate text-gray-600', { 'ml-4': !!icon })}>
                    {get(selectedOption, 'key', placeholder || '-')}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="size-5 text-gray-400 group-hover:text-gray-600"
                        aria-hidden="true"
                    />
                </span>
            </Listbox.Button>
            <Transition
                as={Fragment}
                enter="transition-all duration-100"
                enterFrom="opacity-0 -translate-y-4"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-100"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-4"
            >
                <Listbox.Options
                    className={cn(
                        'scrollable-list absolute z-10 max-h-60 w-full overflow-auto rounded-lg bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                        dropdownClass,
                        direction === 'up' ? 'bottom-12 mb-2' : 'mt-2',
                    )}
                >
                    {hasSearch && (
                        <div className="relative z-20 mb-2 flex h-10 items-center rounded-md border bg-white pl-3 pr-4 text-gray-400 focus-within:border-blue-500 focus-within:text-blue-500 focus-within:ring-1 focus-within:ring-blue-500">
                            <MagnifyingGlassIcon className="size-5" />
                            <input
                                type="text"
                                onChange={handleSearch}
                                placeholder={t('search')}
                                value={query}
                                className="w-full bg-transparent pl-2 text-sm text-gray-400 outline-none focus:text-gray-600"
                                ref={inputRef}
                            />
                        </div>
                    )}
                    {filteredOptions.map((option, index) => (
                        <Option option={option} key={`${option.key}-${index}`} />
                    ))}
                    {!filteredOptions.length && (
                        <div className="w-full px-4 py-2 text-sm font-medium text-gray-400">
                            {isSearching ? t('loading') : t('no-results')}
                        </div>
                    )}
                </Listbox.Options>
            </Transition>
        </div>
    );
};

export default DropdownContents;
