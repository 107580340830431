import { useState, useEffect } from 'react';

import { useVideoDetails } from '@/hooks/useVideoDetails';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import Label from '@/ui/components/_BlockEditFields/Label';
import VideoDuration from '@/ui/components/_BlockEditFields/VideoDuration';
import { secondsToHms, hmsToSeconds } from '@/utils/video/formatDuration';

import type { KeyboardEvent, ChangeEvent } from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    videoUrl: string;
    label?: string;
    submit: () => void;
}

const DurationInput = ({ label, input, videoUrl, submit }: Props) => {
    const [inputValue, setInputValue] = useState(secondsToHms(input.value));
    const [totalDuration, setTotalDuration] = useState('');

    const videoDetails = useVideoDetails(videoUrl);

    useEffect(() => {
        const totalDuration = secondsToHms(videoDetails.duration);
        setTotalDuration(totalDuration);
    }, [videoDetails]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);

        const seconds = hmsToSeconds(event.target.value);

        input.onChange(seconds);
    };

    const handleBlur = () => {
        const pattern = /^\d+:\d{2}:\d{2}$/gm;
        const isValidPattern = pattern.test(inputValue);
        const totalSeconds = hmsToSeconds(totalDuration);
        const inputSeconds = hmsToSeconds(inputValue);
        const isTooLong = inputSeconds > totalSeconds;

        if (!isValidPattern || isTooLong) {
            setInputValue(totalDuration);

            input.onChange(totalSeconds);
        }

        setTimeout(submit);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === 'Enter') {
            return event.currentTarget.blur();
        }
    };

    return (
        <FieldContainer>
            {label && <Label text={label} />}
            <VideoDuration
                onChange={handleChange}
                value={inputValue}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                totalDuration={totalDuration}
            />
        </FieldContainer>
    );
};

export default DurationInput;
