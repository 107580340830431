import { Listbox } from '@headlessui/react';

import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { cn } from '@/utils/cn';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    option: DropdownOption;
}

const Option = ({ option }: Props) => {
    return (
        <Listbox.Option
            key={option.key}
            className={({ active }) =>
                cn(
                    'relative cursor-pointer select-none rounded-md p-2 text-sm',
                    active ? 'bg-blue-50 text-blue-500' : 'text-gray-400',
                )
            }
            value={option.value}
        >
            {({ selected }) => {
                return (
                    <div className="flex items-center">
                        {(option.customSelectIcon || option.icon) && (
                            <div className="mr-2 size-5">
                                <>
                                    {/* @ts-ignore */}
                                    <em-emoji
                                        id={getCleanIconId(
                                            (option.customSelectIcon || option.icon) as string,
                                        )}
                                        size="20px"
                                    />
                                </>
                            </div>
                        )}
                        <span
                            className={cn(
                                'block truncate',
                                selected ? 'text-blue-500' : 'text-gray-400',
                            )}
                        >
                            {option.key}
                        </span>
                    </div>
                );
            }}
        </Listbox.Option>
    );
};

export default Option;
