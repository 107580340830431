import { NAME } from '@/app/editor/editor/constants';

import { XMarkIcon } from '@heroicons/react/16/solid';
import { PlayIcon } from '@heroicons/react/24/outline';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { useRef, useEffect, useState } from 'react';

import Label from '@/ui/components/_BlockEditFields/Label';
import ButtonLoadingSpinner from '@/ui/components/ButtonLoadingSpinner';
import Tooltip from '@/ui/components/Tooltip';
import { getServiceByUrl } from '@/utils/video/getServiceByUrl';

import type { KeyboardEvent } from 'react';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

const YouTube = dynamic(() => import('./icons/YouTube'));
const DailyMotion = dynamic(() => import('./icons/DailyMotion'));
const Facebook = dynamic(() => import('./icons/Facebook'));
const MixCloud = dynamic(() => import('./icons/MixCloud'));
const SoundCloud = dynamic(() => import('./icons/SoundCloud'));
const Streamable = dynamic(() => import('./icons/Streamable'));
const Twitch = dynamic(() => import('./icons/Twitch'));
const Vimeo = dynamic(() => import('./icons/Vimeo'));
const Wistia = dynamic(() => import('./icons/Wistia'));

export interface Props {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    placeholder: string;
    label?: string;
    autoFocus?: boolean;
    loading?: boolean;
    submit: () => void;
    allowedServices?: string[];
}

const serviceIconMap = {
    youtube: YouTube,
    youtu: YouTube,
    vimeo: Vimeo,
    soundcloud: SoundCloud,
    twitch: Twitch,
    wistia: Wistia,
    facebook: Facebook,
    mixcloud: MixCloud,
    streamable: Streamable,
    dailymotion: DailyMotion,
};

const getServiceIcon = ({
    notAllowed,
    loading,
    service,
    tooltipText,
}: {
    notAllowed: boolean;
    loading: boolean;
    service: string;
    tooltipText: string;
}) => {
    if (notAllowed) {
        return (
            <Tooltip content={tooltipText}>
                <XMarkIcon className="ml-4 size-5 flex-shrink-0 text-red-500" />
            </Tooltip>
        );
    }

    if (loading) {
        return <ButtonLoadingSpinner className="ml-4 size-5 flex-shrink-0" />;
    }

    const Icon = serviceIconMap[service] ? serviceIconMap[service] : PlayIcon;

    return <Icon className="ml-4 size-5 flex-shrink-0 overflow-hidden" />;
};

const VideoUrl = ({
    input,
    placeholder,
    autoFocus,
    loading,
    submit,
    label,
    allowedServices = Object.keys(serviceIconMap),
}: Props) => {
    const { t } = useTranslation(NAME);
    const inputRef = useRef(null);

    const [service, setService] = useState('');
    const [notAllowed, setNotAllowed] = useState(false);

    useEffect(() => {
        const service = getServiceByUrl(input.value);

        if (!allowedServices.includes(service)) {
            return setNotAllowed(true);
        }

        setNotAllowed(false);
        setService(service);
    }, [allowedServices, input.value]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Enter') {
            inputRef?.current?.blur();
        }
    };

    const handleBlur = () => {
        if (notAllowed || !service) {
            return;
        }

        submit();
    };

    return (
        <>
            {label && <Label text={label} />}
            <div className="group relative flex h-12 items-center rounded-lg bg-gray-100 text-gray-400 focus-within:text-blue-500">
                {getServiceIcon({
                    notAllowed,
                    loading,
                    service,
                    tooltipText: t('service-not-allowed'),
                })}
                <input
                    {...input}
                    type="text"
                    className="placeholder-normal h-12 w-full truncate rounded-md bg-transparent pl-2 text-gray-600 outline-none sm:text-sm"
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    ref={inputRef}
                />
            </div>
        </>
    );
};

export default VideoUrl;
