import { FaceSmileIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';

import Label from '@/ui/components/_BlockEditFields/Label';
import RadioSlider from '@/ui/components/RadioSlider';

import type { RadioSliderOption, RadioSliderSelection } from '@/ui/types';
import type { WrappedFieldInputProps } from 'redux-form';

export interface Props {
    input: WrappedFieldInputProps;
    srcTypeOptions: RadioSliderOption[];
    label?: string;
    submit: () => void;
}

const MediaSrcTypeSelect = ({ input, label, srcTypeOptions, submit }: Props) => {
    const [activeOption, setActiveOption] = useState<RadioSliderSelection>({
        name: srcTypeOptions[0]?.name,
        value: srcTypeOptions[0]?.value,
        index: 0,
    });
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if (input.value === 'image') {
            setActiveOption({ name: 'image', value: 'image', index: 1 });
        }

        // initial value set -> show the component
        setMounted(true);
    }, [input.value]);

    const handleChange = (option: RadioSliderSelection) => {
        setActiveOption(option);

        input.onChange(option.value);

        setTimeout(submit);
    };

    // skip the initial animation if srcType is not `icon`
    if (!mounted) {
        return null;
    }

    return (
        <div>
            {label && <Label text={label} />}
            <RadioSlider
                onChange={handleChange}
                items={srcTypeOptions}
                activeIndex={activeOption.index}
            />
        </div>
    );
};

MediaSrcTypeSelect.defaultProps = {
    srcTypeOptions: [
        {
            name: 'icon',
            value: 'icon',
            render: () => <FaceSmileIcon className="size-5" />,
        },
        {
            name: 'image',
            value: 'image',
            render: () => <PhotoIcon className="size-5" />,
        },
    ],
};

export default MediaSrcTypeSelect;
