import { useAuthGuard } from '@/app/auth/hooks/useAuthGuard';

import { OpenerView } from './Opener.view';

import type { ReactNode } from 'react';

export interface Props {
    children?: ReactNode;
}

export const OpenerController = ({ children }: Props) => {
    const isAuthenticated = useAuthGuard();

    return <OpenerView showChildren={isAuthenticated}>{children}</OpenerView>;
};
