import { animated, useTransition } from '@react-spring/web';
import Image from 'next/image';

import { isDecember } from '@/utils/dates';
import { getIsWorkspaceEnv } from '@/utils/environments';
import { useIsMounted } from '@/utils/hooks/useMounted';

import type { ReactNode } from 'react';

interface Props {
    showChildren: boolean;
    children?: ReactNode;
}

export const OpenerView = ({ showChildren, children }: Props) => {
    const isMounted = useIsMounted();
    const isWorkspaceEnv = getIsWorkspaceEnv({ genericHost: true });
    const perspectiveLogo = isDecember() ? '/logo_xmas.png' : '/logo_128x128.png';

    const logoSrc = isWorkspaceEnv ? '/workspace-icon.svg' : perspectiveLogo;
    const logoAlt = isWorkspaceEnv ? 'workspace logo' : 'perspective logo';

    const transitions = useTransition(showChildren, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: showChildren,
    });

    // WORKAROUND: Otherwise Perspective Logo is always shown
    if (!isMounted) {
        return null;
    }

    return transitions((style, item) => {
        if (!item) {
            return (
                <animated.div
                    style={style}
                    className="absolute inset-x-0 top-0 flex h-[90vh] w-screen items-center justify-center"
                    aria-hidden
                >
                    <div className="animate-pulse text-[0]">
                        <Image src={logoSrc} alt={logoAlt} width={48} height={48} priority />
                    </div>
                </animated.div>
            );
        }

        return (
            <animated.div className="h-[inherit]" style={style}>
                {children}
            </animated.div>
        );
    });
};
