import { useMemo } from 'react';

import Dropdown from '@/ui/components/_BlockEditFields/Dropdown';
import FlagEmoji from '@/ui/components/FlagEmoji/FlagEmoji';
import { countryCodes, getLocalisedName } from '@/utils/countries';

import type { DropdownOption } from '@/ui/types';
import type { WrappedFieldInputProps } from 'redux-form';
import type { Language } from 'types/generic';

interface Props {
    input: WrappedFieldInputProps;
    language: Language;
    submit: () => void;
    tooltip?: string;
}

const DEFAULT_COUNTRY = 'DE';

const CountryDropdown = ({ input, language, submit, tooltip }: Props) => {
    const { onChange } = input;
    const value = input.value ?? DEFAULT_COUNTRY;

    const options: DropdownOption[] = useMemo(() => {
        return countryCodes
            .map((code) => ({
                key: getLocalisedName(code, language),
                value: code,
                icon: <FlagEmoji code={code} />,
            }))
            .sort((codeA, codeB) => codeA.key.localeCompare(codeB.key));
    }, [language]);

    const handleChange = (val: string) => {
        onChange(val);
        setTimeout(submit);
    };

    return (
        <Dropdown
            hasSearch={true}
            icon={() => <FlagEmoji code={value} />}
            options={options}
            value={value}
            onChange={handleChange}
            dropdownClass="z-30"
            stickySelectedOption={true}
            tooltip={tooltip}
        />
    );
};

export default CountryDropdown;
