import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import find from 'lodash/find';
import get from 'lodash/get';
import { useRef, Fragment } from 'react';

import { getCleanIconId } from '@/app/editor/blocks/helpers';
import { useDropdownDirection } from '@/hooks/useDropdownDirection';
import { cn } from '@/utils/cn';

import Option from './Option';

import type { DropdownOption } from '@/ui/types';

export interface Props {
    options: DropdownOption[];
    value: string;
    onChange: (value: string) => void;
    dropdownClass?: string;
    buttonClass?: string;
}

const Dropdown = ({ options, value, onChange }: Props) => {
    const selectRef = useRef(null);
    const selectedOption = find(options, { value });

    const direction = useDropdownDirection(selectRef, 240);

    return (
        <Listbox value={value} onChange={onChange}>
            <div className="relative" ref={selectRef}>
                <Listbox.Button className="group relative flex h-12 w-full cursor-pointer items-center rounded-lg bg-gray-100 pl-4 text-left text-sm focus:outline-none">
                    {selectedOption?.icon && (
                        <div className="mr-2 size-5">
                            {/* @ts-ignore */}
                            <em-emoji
                                id={getCleanIconId(
                                    (selectedOption?.customSelectIcon ||
                                        selectedOption?.icon) as string,
                                )}
                                size="20px"
                            />
                        </div>
                    )}
                    <span className="block flex-1 truncate">{get(selectedOption, 'key', '-')}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="size-5 text-gray-400 group-hover:text-blue-500"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    enter="transition-all duration-100"
                    enterFrom="opacity-0 -translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition-all duration-100"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-4"
                >
                    <Listbox.Options
                        className={cn(
                            'absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                            direction === 'up' ? 'bottom-12 mb-2' : 'mt-2',
                        )}
                    >
                        {options.map((option, index) => (
                            <Option option={option} key={`${option.key}-${index}`} />
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};

export default Dropdown;
