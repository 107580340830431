import { NAME } from '@/app/editor/editor/constants';

import {
    ButtonIcon,
    CheckboxIcon,
    FaceIcon,
    GroupIcon,
    ImageIcon,
    RowSpacingIcon,
    TextIcon,
    ListBulletIcon,
    InputIcon,
    TokensIcon,
    CalendarIcon,
    VideoIcon,
    QuoteIcon,
    Pencil2Icon,
    LayersIcon,
    CheckIcon,
    CodeIcon,
    QuestionMarkCircledIcon,
    DropdownMenuIcon,
    MixIcon,
    ContainerIcon,
    LayoutIcon,
    DotsHorizontalIcon,
} from '@radix-ui/react-icons';
import { useTranslation } from 'next-i18next';

import { getActiveBlockParent, getBlockById } from '@/app/editor/blocks/models/blocks';
import {
    BlockComponentType,
    type BlockResource,
    type MediaSrcType,
} from '@/app/editor/blocks/types';
import { useAppSelector } from '@/core/redux/hooks';

import { FooterIcon } from './icons/FooterIcon';
import { HeaderIcon } from './icons/HeaderIcon';

const iconMap = {
    [BlockComponentType.GRID_ROW]: LayoutIcon,
    [BlockComponentType.GRID_COLUMN]: ContainerIcon,
    [BlockComponentType.QUESTION_MEDIA_ANSWER]: ImageIcon,
    [BlockComponentType.TEXT]: TextIcon,
    [BlockComponentType.BUTTON]: ButtonIcon,
    [BlockComponentType.QUESTION_MEDIA]: TokensIcon,
    [BlockComponentType.QUESTION_MULTIPLE_CHOICE]: CheckboxIcon,
    [BlockComponentType.QUESTION_TEXT]: CheckboxIcon,
    [BlockComponentType.QUESTION_TEXT_ANSWER]: CheckIcon,
    [BlockComponentType.DIVIDER]: RowSpacingIcon,
    [BlockComponentType.ICON]: MixIcon,
    [BlockComponentType.LIST]: ListBulletIcon,
    [BlockComponentType.LIST_ITEM]: ListBulletIcon,
    [BlockComponentType.LOGOS]: DotsHorizontalIcon,
    [BlockComponentType.INPUT]: InputIcon,
    [BlockComponentType.HEADER]: HeaderIcon,
    [BlockComponentType.FOOTER]: FooterIcon,
    [BlockComponentType.SLIDER_IMAGE]: LayersIcon,
    [BlockComponentType.SLIDER_TESTIMONIAL]: QuoteIcon,
    [BlockComponentType.WEBINAR]: VideoIcon,
    [BlockComponentType.EMBED]: CodeIcon,
    [BlockComponentType.ACCORDION]: QuestionMarkCircledIcon,
    [BlockComponentType.ACCORDION_ITEM]: DropdownMenuIcon,
};

export const useBlockDetails = (block: BlockResource) => {
    const { t } = useTranslation(NAME);

    // For questionForms
    const childFormId = block?.relationships?.components?.data[0]?.id;

    const childBlock = useAppSelector((state) => getBlockById(state, childFormId));
    const parent = useAppSelector(getActiveBlockParent);

    const componentType = block.attributes.componentType;
    const parentType = parent?.attributes?.componentType;

    let title = t(componentType);
    let helpTooltipKey = componentType as string;
    let icon = iconMap[componentType] || GroupIcon;

    // media blocks can be 3 different types (video, image, emoji)
    if (componentType === BlockComponentType.MEDIA) {
        const type: MediaSrcType = block.attributes.content.srcType;

        title = t(`media-${type}`);
        helpTooltipKey = type === 'video' ? 'media' : type === 'image' ? 'image' : 'emoji';
        icon = type === 'video' ? VideoIcon : type === 'image' ? ImageIcon : FaceIcon;
    }

    // questionTextAnswer blocks can be 2 different types (question text or multiple choice)
    if (componentType === BlockComponentType.QUESTION_TEXT_ANSWER && parent) {
        title = t(componentType);
        helpTooltipKey = parentType;
    }

    // QuestionMediaAnswer Text should have a different Headline
    if (
        parentType === BlockComponentType.QUESTION_MEDIA_ANSWER &&
        (componentType === BlockComponentType.TEXT || componentType === BlockComponentType.MEDIA)
    ) {
        title = t('questionTextAnswer');
        helpTooltipKey = 'questionMediaAnswerText';
        icon = CheckIcon;
    }

    // questionForms can also be 2 different types (Calendly or normal)
    if (componentType === BlockComponentType.QUESTION_FORM && childBlock) {
        const isCalendlyForm =
            childBlock?.attributes?.componentType === BlockComponentType.FORM_URL;

        helpTooltipKey = isCalendlyForm ? 'questionFormCalendly' : helpTooltipKey;
        title = isCalendlyForm ? 'Calendly' : t('form');
        icon = isCalendlyForm ? CalendarIcon : Pencil2Icon;
    }

    // CRE-260: We want to show different headlines for the different embed block providers.
    if (componentType === BlockComponentType.EMBED) {
        const provider = block?.attributes?.content?.provider;
        title = `${t('blocks:embed-' + provider)} ${title}`;
    }

    return {
        title,
        helpTooltipKey,
        icon,
    };
};
