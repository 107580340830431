import type modalMap from '@/app/modals/map';
import type { ComponentProps } from 'react';

export interface ModalOptions {
    onClose?: () => void;
    className?: string;
    overlayClassName?: string;
    flexClassName?: string;
    shouldCloseOnOverlayClick?: boolean;
}

export const enum Modals {
    // Common
    DELETION_ALERT = 'DELETION_ALERT',

    // Campaigns
    DUPLICATE_CAMPAIGN = 'DUPLICATE_CAMPAIGN',
    CONFIRM_ARCHIVE_CAMPAIGN = 'CONFIRM_ARCHIVE_CAMPAIGN',
    HOW_TO_EMBED_FUNNEL_MODAL = 'HOW_TO_EMBED_FUNNEL_MODAL',

    // Domains
    FACEBOOK_VERIFICATION = 'FACEBOOK_VERIFICATION',
    DOMAIN_SETUP_TUTORIAL = 'DOMAIN_SETUP_TUTORIAL',

    // Template Store
    TEMPLATE_DETAILS = 'TEMPLATE_DETAILS',

    // Path to success
    P2S_COURSE_LESSON = 'P2S_COURSE_LESSON',
    P2S_COURSE_COMPLETED = 'P2S_COURSE_COMPLETED',

    // Subscription
    SUBSCRIPTION_CONFIRM = 'SUBSCRIPTION_CONFIRM',

    // Dunning
    DUNNING = 'DUNNING',

    // Editor
    INPUT_TRACKING_NAME = 'INPUT_TRACKING_NAME',
    EDIT_PAGE_SLUG = 'EDIT_PAGE_SLUG',
    EDIT_PAGE_SCRIPT = 'EDIT_PAGE_SCRIPT',
    COPY_PAGE_TO_FUNNEL = 'COPY_PAGE_TO_FUNNEL',
    LEGAL_SETTINGS = 'LEGAL_SETTINGS',
    CALENDLY_PREVIEW = 'CALENDLY_PREVIEW',
    CALENDLY_INTRO = 'CALENDLY_INTRO',
    EMBED_INTRO = 'EMBED_INTRO',
    ADD_CUSTOM_VARIABLE = 'ADD_CUSTOM_VARIABLE',
    EDIT_VARIABLE_FALLBACK = 'EDIT_VARIABLE_FALLBACK',
    SPLIT_TESTING_INTRO = 'SPLIT_TESTING_INTRO',
    PASTE_EMBED_SNIPPET = 'PASTE_EMBED_SNIPPET',
    APPLY_THEME_MODAL = 'APPLY_THEME_MODAL',
    CUSTOM_FONTS_MODAL = 'CUSTOM_FONTS_MODAL',
    NEW_SENDER_MODAL = 'NEW_SENDER_MODAL',

    // Image Library
    IMAGE_LIBRARY = 'IMAGE_LIBRARY',
    IMAGE_CROPPING = 'IMAGE_CROPPING',

    // Integrations
    INTEGRATION = 'INTEGRATION',
    INTEGRATION_PROFILE = 'INTEGRATION_PROFILE',
    WEBHOOKS = 'WEBHOOKS',

    // Lead Notifications
    LEAD_NOTIFICATIONS = 'LEAD_NOTIFICATIONS',

    // Account Settings
    CHANGE_NAME = 'CHANGE_NAME',
    CHANGE_EMAIL = 'CHANGE_EMAIL',
    CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    DELETE_ACCOUNT = 'DELETE_ACCOUNT',
    INVOICES = 'INVOICES',
    MAKE_INTEGRATION = 'MAKE_INTEGRATION',

    // Analytics
    END_SPLIT_TEST = 'END_SPLIT_TEST',
    EXPORT_SESSIONS = 'EXPORT_SESSIONS',

    // Funnel settings
    FUNNEL_SETTINGS = 'FUNNEL_SETTINGS',

    // Onboarding goal
    GOAL_HELP = 'GOAL_HELP',

    // CRM
    SHARE_CONTACTS_MODAL = 'SHARE_CONTACTS_MODAL',
    DELETE_CRM_CONTACTS = 'DELETE_CRM_CONTACTS',
    DELETE_STATUS_PROPERTY = 'DELETE_STATUS_PROPERTY',
    STATUS_TEMPLATES = 'STATUS_TEMPLATES',
    CONFIRM_FILE_DELETION = 'CONFIRM_FILE_DELETION',
    ADD_CONTACT = 'ADD_CONTACT',
    STATUS_REPLACEMENT = 'STATUS_REPLACEMENT',

    // Workspace
    WORKSPACE_SETUP_EXIT = 'WORKSPACE_SETUP_EXIT',
    WORKSPACE_SETTINGS = 'WORKSPACE_SETTINGS',

    // Referral Dashboard
    REFERRAL_WATCH_VIDEO = 'REFERRAL_WATCH_VIDEO',
}

// If you get a TS error here, make sure you added the new modal type mapping to "map.ts".
export type ModalProps<Type extends Modals> = ComponentProps<(typeof modalMap)[Type]>;

export interface ModalData<Type extends Modals> {
    type: Type;
    props: ModalProps<Type>;
    options: ModalOptions;
}
