import type { Relationship, Resource } from '@/core/api/types';
import type { ComponentProps, ReactNode } from 'react';

export type DnsProvider = 'All-Inkl' | 'Strato' | 'IONOS' | 'GoDaddy' | 'Namecheap';

export type DomainAvailabilityError = 'taken' | 'caa' | 'root-domain';

export type DomainAvailabilityWarning = 'cname-already-configured';

export interface DomainMenuItem {
    name: string;
    icon: (props: ComponentProps<'svg'>) => ReactNode;
    text: string;
    onClick: () => void;
}

export const enum DomainType {
    sub = 'sub',
    custom = 'custom',
    instant = 'instant',
    managed = 'managed',
    cloudflare = 'cloudflare',
}

export const enum DomainStatus {
    verified = 'verified',
    /**
     * @deprecated use `pending` instead
     */
    connected = 'connected',
    pending = 'pending',
}

export const enum CdnStatus {
    success = 'success',
    connected = 'connected',
}

// Domain Resource
export interface DomainAttributes {
    name: string;
    createdAt?: string;
    status?: 'verified' | 'unverified' | 'pending' | 'shuttingDown';
    cdnStatus?: 'verified' | 'unverified' | 'pending' | 'shuttingDown' | 'success';
    dnsStatus?: 'verified' | 'unverified' | 'pending' | 'shuttingDown';
    dns?: {
        status?: 'verified' | 'verifying' | 'unverified';
    };
    externalVerificationId?: string;
    default: boolean;
    isReady?: boolean;
    url: string;
    urlWww?: string;
    cdnUrl?: string;
    ssl?: {
        status: 'inProgress' | 'failed';
    };
    provider?: DnsProvider;
}

export interface DomainRelationships {
    company?: Relationship;
}

export type DomainResource = Resource<DomainAttributes, DomainRelationships>;

// Domain Availability

export interface DomainAvailability {
    available: boolean;
    dnsProvider?: DnsProvider;
    currentCnameRecord?: string;
    caaIssuers: string[];
}
