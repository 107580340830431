import { reinsertNodeAtIndexStep } from '@/app/editor/engine/core/utils/transaction/steps/reinsertNodeAtIndexStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { makeEditorEngineError } from '@/app/editor/engine/utils/error/makeEditorEngineError';
import { guid } from '@/app/editor/engine/utils/guid';

import type {
    EditorEngineActionCreatorPayload,
    EditorEngineParentId,
} from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The ID of the node that will be moved.
     */
    id: string;
    /**
     * The ID of the new parent node.
     */
    newParentId?: EditorEngineParentId;
    /**
     * The index where the node will be moved.
     */
    newIndex: number;
}

/**
 * Action that will move a node.
 */
export const moveBlockAction = (({
    args: { id, newParentId, newIndex },
    nodeManager,
    extraContext: { backendEntities },
}) => {
    const forwardInOperationId = guid();
    const backwardInOperationId = guid();
    let oldParentId: EditorEngineParentId;
    let oldIndex: number;

    return {
        name: PerspectiveEditorEngineActionName.MoveBlock,
        debug: {
            forwardInOperationId,
            backwardInOperationId,
            oldParentId,
            oldIndex,
        },
        getTransaction() {
            return [
                reinsertNodeAtIndexStep({
                    id,
                    newParentId,
                    newIndex,
                }),
            ];
        },
        onBeforeOptimisticForward() {
            oldParentId = nodeManager.getParentId(nodeManager.getNode(id));
            oldIndex = nodeManager.getNodeIndex(id);
        },
        async execute() {
            await backendEntities.moveBlock({
                operationId: forwardInOperationId,
                componentId: id,
                newParentId: newParentId,
                newIndex,
                resolveVirtualId: nodeManager.resolveVirtualId,
            });

            return {
                success: true,
            };
        },
        async undo() {
            if (typeof oldIndex !== 'number') {
                return {
                    success: false,
                    error: makeEditorEngineError({
                        error: `oldIndex missing when undoing ${PerspectiveEditorEngineActionName.MoveBlock}`,
                        debug: {
                            id,
                        },
                    }),
                };
            }

            await backendEntities.moveBlock({
                operationId: backwardInOperationId,
                componentId: id,
                newParentId: oldParentId,
                newIndex: oldIndex,
                resolveVirtualId: nodeManager.resolveVirtualId,
            });

            return {
                success: true,
            };
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<Payload, {}>;
