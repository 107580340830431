import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import { getFormValues } from 'redux-form';

import {
    getUsedUniqueInputsByFormBlockId,
    setUniqueFormInputs,
    updateInputType,
} from '@/app/editor/blocks/models/inputTypes';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import IconDropdown from '@/ui/components/_BlockEditFields/IconDropdown';
import Label from '@/ui/components/_BlockEditFields/Label';

import { inputTypeOptions } from './inputTypeOptions';

import type { BlockResource } from '@/app/editor/blocks/types';
import type { DropdownOption } from '@/ui/types';
import type { Language } from 'types/generic';

export interface Props {
    blockId: string;
    formBlockId: string;
    label?: string;
}

const InputType = ({ blockId, formBlockId, label }: Props) => {
    const { locale } = useRouter();
    const dispatch = useAppDispatch();
    const usedUniqueInputs = useAppSelector((state) =>
        getUsedUniqueInputsByFormBlockId(state, formBlockId),
    );
    const formValues = useAppSelector((state) => getFormValues(blockId)(state)) as BlockResource;
    const currentFieldValue = formValues?.attributes?.content?.field;

    const [options, setOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        const options = [];

        const availableInputTypes = inputTypeOptions[locale].filter((inputType) => {
            // keep current selection
            if (inputType?.field === currentFieldValue) {
                return true;
            }

            // Remove used unique inputs
            return !usedUniqueInputs?.includes(inputType.field);
        });

        availableInputTypes.forEach((type) => {
            options.push({
                key: type.name,
                value: type.field,
                icon: type.icon,
                customSelectIcon: type.customSelectIcon,
            });
        });

        setOptions(options);
    }, [locale, usedUniqueInputs, currentFieldValue]);

    const handleSelect = async (field: string) => {
        await dispatch(updateInputType(blockId, field, locale as Language));

        // update available options after updating the input type
        dispatch(setUniqueFormInputs(formBlockId));
    };

    return (
        <>
            {label && <Label text={label} />}
            <IconDropdown options={options} value={currentFieldValue} onChange={handleSelect} />
        </>
    );
};

export default InputType;
