import { getFlagByCountryCode } from '@/utils/countries';

import type { CountryCode } from '@/utils/countries';
import type { HTMLProps } from 'react';

interface Props {
    code: CountryCode;
    className?: HTMLProps<HTMLDivElement>['className'];
}

const FlagEmoji = ({ code, className = 'text-xl leading-0' }: Props) => (
    <span className={className}>{getFlagByCountryCode(code)}</span>
);

export default FlagEmoji;
