export const NAME = 'messages';

export const MESSAGE_DELETION_REMINDER_LS_KEY = 'message-deletion-reminder';

export enum KnownMessageTypes {
    email = 'emailTemplate',
}

export const SENDERS_SLIDES = ['intro', 'email', 'verify/completed'] as const;

export const DATE_FORMAT = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
};

// Validations

export const MAX_MESSAGE_NAME_LENGTH = 200;
export const MAX_SUBJECT_LENGTH = 200;
export const MAX_SENDER_SUBDOMAIN_COUNT = 1;
export const MIN_SENDER_DOMAIN_COUNT = 2;

// Requests

export const PREFETCH_STALE_TIME = 60_000; // 60 seconds

export const ENDPOINTS = {
    GET: {
        messages: (campaignId?: string) => `v1/campaigns/${campaignId}/templates`,
        senders: () => 'sender',
    },
    POST: {
        message: (campaignId: string) => `v1/campaigns/${campaignId}/templates`,
        sender: () => 'sender',
        test: (campaignId: string, messageId: string) =>
            `v1/campaigns/${campaignId}/templates/${messageId}/preview`,
        publish: (campaignId: string, messageId: string) =>
            `v1/campaigns/${campaignId}/templates/${messageId}/publish`,
        deactivate: (campaignId: string, messageId: string) =>
            `v1/campaigns/${campaignId}/templates/${messageId}/deactivate`,
        senderVerifyInformation: (senderId: string) => `sender/${senderId}/verify`,
    },
    PATCH: {
        message: (campaignId: string, messageId: string) =>
            `v1/campaigns/${campaignId}/templates/${messageId}`,
    },
    DELETE: {
        message: (campaignId: string, messageId: string) =>
            `v1/campaigns/${campaignId}/templates/${messageId}`,
        sender: (senderId: string) => `sender/${senderId}`,
    },
} as const;

export const QUERY_KEYS = {
    messages: (campaignId?: string) => ['messages', 'list', campaignId ?? ''],
    senders: () => ['senders', 'list'],
    senderVerifyInformation: (senderId: string) =>
        ['sender', 'verifyInformation', senderId] as const,
} as const;

export const MUTATION_KEYS = {
    addMessage: (campaignId?: string) => ['message', 'add', campaignId],
    updateMessage: (campaignId?: string) => ['message', 'update', campaignId],
    removeMessage: (campaignId?: string) => ['message', 'remove', campaignId],
    testMessage: (campaignId?: string) => ['message', 'test', campaignId],
    publishMessage: (campaignId?: string) => ['message', 'publish', campaignId],
    deactivateMessage: (campaignId?: string) => ['message', 'deactivate', campaignId],
    addSender: () => ['sender', 'add'],
    removeSender: (senderId: string) => ['sender', 'remove', senderId],
} as const;
