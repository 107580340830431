import { getAllChildrenBlocksRecursively } from '@/app/editor/commands/utils/getAllChildrenBlocksRecursively';
import { EditorEngineLogLevel } from '@/app/editor/engine/core/types/log';
import { removeNodeStep } from '@/app/editor/engine/core/utils/transaction/steps/removeNodeStep';
import { PerspectiveEditorEngineActionName } from '@/app/editor/engine/types';
import { apiDelete, apiPost } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';

import type { EditorEngineActionCreatorPayload } from '@/app/editor/engine/core/types';
import type { PerspectiveEditorEngineActionCreator } from '@/app/editor/engine/types';
import type { PageResource } from '@/app/editor/pages/types';
import type { ResponseData } from '@/core/api/types';

interface Payload extends EditorEngineActionCreatorPayload {
    /**
     * The ID of the block to delete.
     */
    id: string;
}

/**
 * Action that will delete a block.
 */
export const deleteBlockAction = (({ args: { id }, documentManager, nodeManager, log }) => {
    const originalNode = nodeManager.getNode(id);
    const originalPage = documentManager.activePage;
    const originalIndex = nodeManager.getNodeIndex(id);

    return {
        name: PerspectiveEditorEngineActionName.DeleteBlock,
        debug: {},
        shouldBeSkipped() {
            if (!originalNode || !originalPage) {
                log({
                    level: EditorEngineLogLevel.Warning,
                    message: `Block or page not found — skipping action ${PerspectiveEditorEngineActionName.DeleteBlock}`,
                });

                return true;
            }

            return false;
        },
        getTransaction() {
            return [
                removeNodeStep({
                    id,
                }),
            ];
        },
        async execute() {
            await apiDelete(`/components/${nodeManager.resolveVirtualId(id)}`);

            return {
                success: true,
            };
        },
        async undo() {
            const componentsToRestore = getAllChildrenBlocksRecursively(originalNode.block).map(
                nodeManager.resolveVirtualIdsInBlock,
            );

            const updatedPage = await apiPost<ResponseData<PageResource>>(
                `/pages/${originalPage.id}/components`,
                {
                    data: {
                        position: originalIndex,
                        components: [componentsToRestore],
                    },
                },
            );

            const updatedPageId = getDataFromResponse(updatedPage)?.id;

            if (!updatedPageId) {
                log({
                    level: EditorEngineLogLevel.Warning,
                    message: 'Did not find page response after undoing block deletion',
                    context: {
                        originalPageId: originalPage.id,
                    },
                });
            }

            return {
                success: true,
                result: {
                    updatedPageId,
                },
            };
        },
        onAfterOptimisticForward() {
            if (documentManager.activeBlock?.id === id) {
                documentManager.setActiveView('pages');
            }
        },
        async onAfterUndo({ result }) {
            if (!result.updatedPageId) {
                return;
            }

            documentManager.setActivePage(result.updatedPageId);
        },
    };
}) satisfies PerspectiveEditorEngineActionCreator<
    Payload,
    {},
    {},
    {
        updatedPageId?: string;
    }
>;
