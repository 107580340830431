import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import get from 'lodash/get';
import { nanoid } from 'nanoid';
import { useRef, Fragment, useMemo } from 'react';

import { useDropdownDirection } from '@/hooks/useDropdownDirection';
import { cn } from '@/utils/cn';

import Option from '../Dropdown/Option';

import type { DropdownCategory, DropdownOption } from '@/ui/types';
import type { ComponentProps, ReactNode } from 'react';

export type CategorizedDropdownButtonIcon =
    | ((props: ComponentProps<'svg'>) => ReactNode)
    | ((props: ComponentProps<'span'>) => ReactNode);

export interface Props {
    categories: DropdownCategory[];
    value: string;
    onChange: (value: string) => void;
    dropdownClass?: string;
    buttonClass?: string;
    placeholder?: string;
    buttonIcon?: CategorizedDropdownButtonIcon;
}

const CategorizedDropdown = ({
    categories,
    value,
    onChange,
    dropdownClass,
    buttonClass,
    placeholder,
    buttonIcon,
}: Props) => {
    const selectRef = useRef(null);
    const ButtonIcon = buttonIcon;

    const selectedOption: DropdownOption | undefined = useMemo(() => {
        for (const category of categories) {
            for (const option of category.options) {
                if (option.value === value) {
                    return option;
                }
            }
        }
    }, [categories, value]);

    const direction = useDropdownDirection(selectRef, 240);

    return (
        <Listbox value={value} onChange={onChange}>
            <div className="relative" ref={selectRef}>
                <Listbox.Button
                    className={cn(
                        'group relative flex h-12 w-full cursor-pointer items-center rounded-lg bg-gray-100 pl-4 text-left text-sm text-gray-500 hover:border-blue-500 focus:outline-none',
                        buttonClass,
                    )}
                >
                    {ButtonIcon && (
                        <ButtonIcon className="mr-3 flex size-5 min-w-5 flex-row items-center justify-center text-base font-medium leading-none" />
                    )}
                    <span className="block flex-1 truncate">
                        {get(selectedOption, 'key', placeholder || '-')}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="size-5 text-gray-400 group-hover:text-gray-600"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    enter="transition-all duration-100"
                    enterFrom="opacity-0 -translate-y-4"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition-all duration-100"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 -translate-y-4"
                >
                    <Listbox.Options
                        className={cn(
                            'absolute z-10 max-h-[280px] w-full overflow-auto rounded-md bg-white p-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                            dropdownClass,
                            direction === 'up' ? 'bottom-12 mb-2' : 'mt-2',
                        )}
                    >
                        {categories.map((category, index) => {
                            return (
                                <div
                                    className={index >= 1 ? 'mt-2' : ''}
                                    key={category.name || nanoid()}
                                >
                                    {category.name && (
                                        <p className="px-3 py-2 text-sm font-semibold leading-6">
                                            {category.name}
                                        </p>
                                    )}
                                    {category.options.map((option, index) => (
                                        <Option option={option} key={`${option.key}-${index}`} />
                                    ))}
                                </div>
                            );
                        })}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    );
};

export default CategorizedDropdown;
