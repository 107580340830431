import { Listbox } from '@headlessui/react';
import { PlusIcon, ChevronRightIcon, NoSymbolIcon } from '@heroicons/react/20/solid';
import {
    ArrowRightOnRectangleIcon,
    ArrowTopRightOnSquareIcon,
    BoltIcon,
    LinkIcon,
} from '@heroicons/react/24/outline';

import { cn } from '@/utils/cn';

import type { DropdownOption } from '@/ui/types';
import type { ReactNode } from 'react';

export interface Props {
    option: DropdownOption;
}

export const iconMap = {
    external: ArrowTopRightOnSquareIcon,
    plus: PlusIcon,
    link: LinkIcon,
    bolt: BoltIcon,
    none: NoSymbolIcon,
    arrow: ChevronRightIcon,
    next: ArrowRightOnRectangleIcon,
};

export const OptionIcon = ({ children }: { children: ReactNode }) => (
    <span className="mr-3 flex size-5 items-center justify-center font-medium">{children}</span>
);

const Option = ({ option }: Props) => {
    const Icon = option.icon
        ? typeof option.icon === 'string' && iconMap[option.icon]
            ? iconMap[option.icon]
            : () => option.icon
        : () => null;

    const ActionIcon = option.actionIcon ? iconMap[option.actionIcon] : () => null;

    if (option.isButton) {
        return (
            <button
                onClick={option.action}
                className="flex w-full items-center justify-between rounded-md px-3 py-2 text-sm leading-6 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
            >
                {option.icon && (
                    <OptionIcon>
                        <Icon className="size-4" />
                    </OptionIcon>
                )}
                <span className="block flex-1 truncate text-left">{option.key}</span>
                {option.action && <ActionIcon className="size-5" />}
            </button>
        );
    }

    return (
        <Listbox.Option
            key={option.key}
            className={({ active, selected }) =>
                cn('relative cursor-pointer select-none rounded-md px-3 py-2 text-sm leading-6', {
                    'bg-gray-100 text-gray-600': active && !selected,
                    'text-gray-400': !active,
                    'bg-blue-50': active && selected,
                })
            }
            value={option.value}
            disabled={option.disabled}
        >
            {({ selected }) => (
                <div
                    className={cn(
                        'flex items-center',
                        {
                            'cursor-not-allowed opacity-50': option.disabled,
                        },
                        selected ? 'text-blue-500' : 'text-gray-600',
                    )}
                >
                    {option.icon && (
                        <OptionIcon>
                            <Icon className="size-4" />
                        </OptionIcon>
                    )}
                    <span className="block flex-1 truncate">{option.key}</span>
                    {option.action && (
                        <ActionIcon
                            className="size-5 text-gray-400 hover:text-gray-600"
                            onClick={option.action}
                        />
                    )}
                </div>
            )}
        </Listbox.Option>
    );
};

export default Option;
