import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SpeedInsights } from '@vercel/speed-insights/next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import { Toaster } from 'sonner';

import { store } from '@/core/redux/store';

import { SchnitzelBanner } from '@/app/auth/components/SchnitzelBanner';
import LaunchEventWatcher from '@/app/campaigns/components/LaunchEventWatcher';
import BaseModal from '@/app/modals/components/BaseModal';
import ErrorBoundary from '@/core/errors/ErrorBoundary';
import { PerspectiveQueryClient } from '@/core/queryClient';
import { initAllTracking } from '@/core/tracking';
import Opener from '@/ui/components/Opener';
import { DevLoader } from '@/utils/dev/components/DevLoader';
import { getIsWorkspaceEnv, isProductionEnv } from '@/utils/environments';
import { usePreviousRoute } from '@/utils/hooks/usePreviousRoute';

import '@/ui/styles/global.css';
import '@/ui/components/DatePicker/styles.css';
import '@/ui/components/MediaCarousel/styles.css';

import type { AppPropsWithLayout } from 'types/generic';

const NewDeployToast = dynamic(() => import('@/app/toasts/components/NewDeployToast'), {
    ssr: false,
});

// Monitoring, Tracking & Intercom
initAllTracking();

// eslint-disable-next-line func-style
function App({ Component, pageProps }: AppPropsWithLayout) {
    const getLayout = Component.getLayout || ((page) => page);
    const isWorkspaceEnv = getIsWorkspaceEnv();

    const defaultTitle = isWorkspaceEnv ? 'Workspaces' : 'Perspective Funnels';

    usePreviousRoute();

    const initChargebee = () => {
        // @ts-ignore
        window?.Chargebee?.init({
            site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE,
        });
    };

    return (
        <QueryClientProvider client={PerspectiveQueryClient}>
            <Provider store={store}>
                <div className="relative h-screen w-screen overflow-x-hidden">
                    <Head>
                        <title key="title">{pageProps?.title || defaultTitle}</title>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                        />
                    </Head>

                    <ErrorBoundary>
                        <Opener>{getLayout(<Component {...pageProps} />)}</Opener>
                        <BaseModal />
                        <Toaster position="bottom-center" />
                        <NewDeployToast />
                        <LaunchEventWatcher />
                    </ErrorBoundary>

                    {/* Vercel Speed Insights */}
                    <SpeedInsights sampleRate={0.005} />

                    <Script
                        src="https://js.chargebee.com/v2/chargebee.js"
                        strategy="afterInteractive"
                        onLoad={initChargebee}
                    />

                    <SchnitzelBanner />
                    {!isProductionEnv() && <DevLoader />}
                </div>
            </Provider>

            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default appWithTranslation(App);
