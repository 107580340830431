import { CursorArrowRaysIcon } from '@heroicons/react/20/solid';
import { SquaresPlusIcon } from '@heroicons/react/20/solid';

import { Basics } from './components/icons/Basics';
import { Embed } from './components/icons/Embed';
import { Forms } from './components/icons/Forms';
import { Layouts } from './components/icons/Layouts';

import type { SectionCategory } from '@/app/editor/sections/types';

export const NAME = 'sections';

export const COLUMN_SECTION_CATEGORIES: SectionCategory[] = [
    {
        name: 'basics',
        color: 'blue',
        icon: Basics,
        sections: [
            '1b-text',
            '1b-image',
            '1b-video',
            '1b-button',
            '1b-graphic',
            '1b-list',
            '1b-accordion',
            '1b-divider',
            '1b-testimonial',
            '1b-slider',
            '1b-webinar',
            '1b-logos',
        ],
    },
    {
        name: 'embeds',
        color: 'blue',
        icon: Embed,
        sections: [
            '1b-embed.kununu',
            '1b-embed.trustpilot',
            '1b-embed.provenexpert',
            '1b-embed.googlemaps',
            '1b-embed.customhtml',
        ],
    },
    {
        name: 'forms',
        color: 'blue',
        icon: Forms,
        sections: [
            '1b-form',
            '1b-calendly',
            '1b-form.upload',
            '1b-form.message',
            '1b-form.date',
            '1b-form.dropdown',
            '1b-form.payment',
        ],
    },
    {
        name: 'interactions',
        color: 'blue',
        icon: CursorArrowRaysIcon,
        sections: ['1b-quiz', '1b-multiplechoice', '1b-choice'],
    },
];

export const SECTION_CATEGORIES: SectionCategory[] = [
    {
        name: 'layouts',
        color: 'light-blue',
        icon: Layouts,
        sections: [
            '2c-text-text-button--image',
            '2c-text-list--form',
            '2c-text-text-button--text-list',
            '2c-text-choice--image',
            '2c-text-quiz--image',
            '2c-graphic-text-text--form',
            '2c-graphic-text-text--quiz',
            '2c-graphic-text-text--choice',
            '2c-text-list--multiplechoice',
            '2c-text-text-image--text-form',
            '2c-text-text-button--text-video',
            '2c-text-list--image',
            '2c-video-text-text--2x',
            '3c-image-text-text--3x',
            '4c-graphic-text-text--4x',
        ],
    },
    {
        name: 'cards',
        color: 'light-blue',
        icon: SquaresPlusIcon,
        sections: [
            '1c-text-text-button',
            '1c-text-text-accordion',
            '1c-text-text-list',
            '1c-text-text-form',
            '1c-image-text-text-button',
            '1c-video-text-text',
            '1c-graphic-text-text-button',
            '1c-list-text',
        ],
    },
    ...COLUMN_SECTION_CATEGORIES,
];

export const ELEMENTS_CATEGORY_ID_DE = '5a957843b727462286d2d61e';
export const ELEMENTS_CATEGORY_ID_EN = '61af39fa0ff35780165e4faa';
