import { Listbox } from '@headlessui/react';

import Tooltip from '@/ui/components/Tooltip';

import DropdownContents from './DropdownContents';

import type { DropdownOption } from '@/ui/types';
import type { ComponentProps } from 'react';

export interface Props {
    options: DropdownOption[];
    value: string;
    icon?: (props: ComponentProps<'svg'>) => JSX.Element;
    onChange: (value: string) => void;
    customSearchHandler?: (query: string) => void;
    dropdownClass?: string;
    buttonClass?: string;
    placeholder?: string;
    hasSearch?: boolean;
    isSearching?: boolean;
    stickySelectedOption?: boolean;
    tooltip?: string;
}

const Dropdown = ({ value, onChange, tooltip, ...rest }: Props) => {
    const TooltipWrapper = ({ children }: { children: JSX.Element }) => {
        if (!tooltip) {
            return <>{children}</>;
        }

        return (
            <Tooltip content={tooltip}>
                <div>{children}</div>
            </Tooltip>
        );
    };

    return (
        <TooltipWrapper>
            <Listbox value={value} onChange={onChange}>
                {({ open }) => <DropdownContents value={value} open={open} {...rest} />}
            </Listbox>
        </TooltipWrapper>
    );
};

export default Dropdown;
