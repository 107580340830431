/**
 * The types specific to the Perspective Editor Engine.
 */

import type { usePerspectiveBackendEntities } from './hooks/usePerspectiveBackendEntities';
import type { BlockResource } from '@/app/editor/blocks/types';
import type { EditorEngineByParentRendererProps } from '@/app/editor/engine/core/components/view/getEditorEngineByParentRenderer';
import type { EditorEngineNodeRendererProps } from '@/app/editor/engine/core/components/view/getEditorEngineNodeRenderer';
import type { EditorEngineSequenceRendererProps } from '@/app/editor/engine/core/components/view/getEditorEngineSequenceRenderer';
import type {
    EditorEngineActionCreator,
    EditorEngineActionCreatorPayload,
    EditorEngineBaseTypeInput,
    EditorEngineDocument,
    EditorEngineDraggableConfiguration,
    EditorEngineNodeData,
    EditorEngineTypeInput,
    InsertNodeAtPayload,
} from '@/app/editor/engine/core/types';
import type { EditorEngineRelativePosition } from '@/app/editor/engine/core/types/util';
import type { createReconciliationReferenceManager } from '@/app/editor/engine/core/utils/entities/createReconciliationReferenceManager';
import type { usePerspectiveDocumentManager } from '@/app/editor/engine/hooks/usePerspectiveDocumentManager';
import type { usePerspectiveNodeManager } from '@/app/editor/engine/hooks/usePerspectiveNodeManager';
import type { perspectiveEditorActions } from '@/app/editor/engine/PerspectiveEditorEngine';

export interface PerspectiveEditorEngineNodeData extends EditorEngineNodeData {
    block: BlockResource;
}

export interface PerspectiveEditorEngineDocument extends EditorEngineDocument {}

export enum PerspectiveEditorEngineReconciliationTrait {
    General = 'General',
}

export type PerspectiveReconciliationReferenceManager = ReturnType<
    typeof createReconciliationReferenceManager<PerspectiveEditorEngineReconciliationTrait>
>;

export interface PerspectiveEditorEngineExtraContext {
    backendEntities: ReturnType<typeof usePerspectiveBackendEntities>;
}

export interface PerspectiveEditorEngineDocumentManagerProps {}

export interface PerspectiveEditorEngineNodeManagerProps {}

export enum PerspectiveEditorEngineActionName {
    ChangeBlock = 'ChangeBlockAction',
    DeleteBlock = 'DeleteBlockAction',
    DuplicateBlock = 'DuplicateBlockAction',
    InsertBlock = 'InsertBlockAction',
    InsertColumn = 'InsertColumnAction',
    JoinBasicBlocks = 'JoinBasicBlocksAction',
    MoveBlock = 'MoveBlockAction',
}

export type PerspectiveEditorEngineBaseTypeInput = EditorEngineBaseTypeInput<
    PerspectiveEditorEngineDocument,
    PerspectiveEditorEngineNodeData,
    PerspectiveEditorEngineExtraContext,
    ReturnType<typeof usePerspectiveDocumentManager>,
    ReturnType<typeof usePerspectiveNodeManager>,
    PerspectiveEditorEngineActionName
>;

export type PerspectiveEditorEngineTypeInput = EditorEngineTypeInput<
    PerspectiveEditorEngineDocument,
    PerspectiveEditorEngineNodeData,
    PerspectiveEditorEngineExtraContext,
    ReturnType<typeof usePerspectiveDocumentManager>,
    ReturnType<typeof usePerspectiveNodeManager>,
    PerspectiveEditorEngineActionName,
    typeof perspectiveEditorActions
>;

export type PerspectiveEditorEngineActionCreator<
    TActionArguments extends EditorEngineActionCreatorPayload,
    TTransactionPreparationResult extends object = object,
    TExecuteResult extends object = object,
    TUndoResult extends object = object,
> = EditorEngineActionCreator<
    PerspectiveEditorEngineBaseTypeInput,
    TActionArguments,
    TTransactionPreparationResult,
    TExecuteResult,
    TUndoResult
>;

export type PerspectiveEditorEngineDraggableConfiguration =
    EditorEngineDraggableConfiguration<PerspectiveEditorEngineTypeInput>;

export type PerspectiveEditorEngineNodeRendererProps =
    EditorEngineNodeRendererProps<PerspectiveEditorEngineTypeInput>;

export type PerspectiveEditorEngineByParentRendererProps =
    EditorEngineByParentRendererProps<PerspectiveEditorEngineTypeInput>;

export type PerspectiveEditorEngineSequenceRendererProps =
    EditorEngineSequenceRendererProps<PerspectiveEditorEngineTypeInput>;

/**
 * An object that will hold payloads for inserting many nodes.
 */
export type PerspectiveEditorEngineNestedInsertNodeAtPayload = {
    payload: InsertNodeAtPayload<PerspectiveEditorEngineNodeData>;
    children: PerspectiveEditorEngineNestedInsertNodeAtPayload[];
};

/**
 * Determines how a block is duplicated.
 */
export enum PerspectiveEditorEngineDuplicationMethod {
    /**
     * The user clicked on the duplicate button.
     */
    Button = 'Button',
    /**
     * The user used a keyboard shortcut.
     */
    Shortcut = 'Shortcut',
}

/**
 * The type of duplication being performed.
 */
export enum PerspectiveEditorEngineDuplicationUseCase {
    /**
     * The use case is block duplication.
     *
     * While the Editor Engine duplicates blocks for multiple reasons, this
     * insertion type means that the use case was, in fact, block duplication.
     */
    Duplication = 'Duplication',
    /**
     * The user is pasting a block that was previously copied.
     */
    Paste = 'Paste',
}

export type ApiDropOperation = {
    /**
     * An optional ID for the operation. If not provided, the backend will
     * generate one. It can be used to sync logs between frontend and backend.
     */
    operationId?: string;
    /**
     * The ID of the component that is being dropped.
     */
    componentId: string;
} & (
    | {
          /**
           * Drop a component within another.
           *
           * A combination of two "in" operations can be used to undo a
           * "besides" operation.
           */
          in: {
              /**
               * The ID of the new parent component.
               *
               * If not provided, the component will be moved to the top level.
               */
              parentId?: string;
              /**
               * The index where the component will be dropped.
               */
              index: number;
              /**
               * If true, the old parent component of the dropped component will
               * be deleted if it has no children.
               */
              deleteDanglingParent?: boolean;
          };
      }
    | {
          /**
           * Drop a basic component besides a top-level basic component.
           */
          besides: {
              /**
               * The ID of the sibling component.
               */
              siblingId: string;
              /**
               * When dropping besides a top-level basic component, a new layout
               * and two columns will be created. This property determines
               * whether the dropped component will be placed in the left
               * or right column.
               *
               * The component with ID `siblingId` will be placed in the other
               * column.
               */
              position: EditorEngineRelativePosition;
          };
      }
);

export type NodeSequenceRowConfiguration = {
    wideLayoutRowLength: number;
    narrowLayoutRowLength: number;
};
