import { EditorState, RichUtils } from 'draft-js';
import findIndex from 'lodash/findIndex';
import { useMemo } from 'react';

import { getEditorState, saveDraftState } from '@/app/editor/blocks/models/draftEditorStates';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import FieldContainer from '@/ui/components/_BlockEditFields/FieldContainer';
import Label from '@/ui/components/_BlockEditFields/Label';
import RadioSlider from '@/ui/components/RadioSlider';

import { getActiveTextSize, sizeMap } from './helper';

import type { TextSizeShort } from '@/app/editor/blocks/types';
import type { RadioSliderSelection } from '@/ui/types';

export interface Props {
    textBlockId: string;
    sizes?: TextSizeShort[];
    label?: string;
}

export const TextSize = ({ textBlockId, sizes = ['S', 'M', 'L', 'XL'], label }: Props) => {
    const dispatch = useAppDispatch();
    const editorState = useAppSelector((state) => getEditorState(state, textBlockId));

    const activeTextSize = getActiveTextSize(editorState);

    const textSizes = useMemo(
        () =>
            sizes.map((size) => {
                return {
                    name: size,
                    value: sizeMap[size],
                    render: () => <>{size}</>,
                };
            }),
        [sizes],
    );

    const activeIndex = findIndex(textSizes, { name: activeTextSize });

    const handleChange = (size: RadioSliderSelection) => {
        if (size.name === activeTextSize) {
            return;
        }

        const editorStateFocused = EditorState.forceSelection(
            editorState,
            editorState.getSelection(),
        );
        const updatedEditorState = RichUtils.toggleBlockType(editorStateFocused, size.value);

        // Update Redux Draft.js State and save the block
        dispatch(saveDraftState(textBlockId, updatedEditorState));
    };

    return (
        <FieldContainer>
            {label && <Label text={label} />}
            <RadioSlider onChange={handleChange} items={textSizes} activeIndex={activeIndex} />
        </FieldContainer>
    );
};

export default TextSize;
